/*
 * @Autor: oops.liu
 */
import native from "@/utils/native.js";
import { HANDLE_BACK } from "@/mixin/mixin_file.js";
export default {
  methods: {
    /**调用APP端左键返回
     * @description:
     * @param {*}
     * @return {*}
     */
    handleLeft() {
      const name = this.$route.name;
      if (name) {
        native(HANDLE_BACK, { name: name });
      } else {
        native(HANDLE_BACK, null);
      }
    },
  },
};
