<!--
 * @Autor: oops.liu
-->
<template>
  <canvas ref="canvas" width="120" height="34" style="cursor: pointer"></canvas>
</template>

<script>
export default {
  components: {},
  props: {
    codes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      colors: ["red", "yellow", "blue", "green", "pink", "black"],
      code_Len: 4,
      ctx: "",
    };
  },
  computed: {},
  methods: {
    reset() {
      const canvas = this.$refs["canvas"];
      this.ctx = canvas.getContext("2d");

      this.ctx.fillStyle = "#BFEFFF";
      this.ctx.fillRect(0, 0, 120, 34);
      this.ctx.font = "20px Verdana";
      let x = 10;
      for (let i = 0; i < this.code_Len; i++) {
        this.ctx.fillStyle = this.colors[Math.floor(Math.random() * 5)];
        this.ctx.fillText(this.codes[i], x, 25);
        x = x + 30;
      }
      const lineNumber = 3; // 线条条数
      const lineX = 120;
      const lineY = 34; // 最大线条坐标
      for (let i = 0; i < lineNumber; i++) {
        this.ctx.strokeStyle = this.colors[Math.floor(Math.random() * 5)];
        this.ctx.beginPath();
        this.ctx.moveTo(
          Math.floor(Math.random() * lineX),
          Math.floor(Math.random() * lineY)
        );
        this.ctx.lineTo(
          Math.floor(Math.random() * lineX),
          Math.floor(Math.random() * lineY)
        );
        this.ctx.stroke();
      }
    },
  },
  created() {},
  mounted() {  this.reset()},
  watch: {
    codes(){
      this.reset()
    }
  },
};
</script>

<style scoped lang="scss">
</style>
