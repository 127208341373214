<!--
 * @Autor: oops.liu
-->
<template>
  <div class="verify_box">
    <navbarbox>
      <van-nav-bar title="商家证照信息" left-arrow @click-left="handleLeft">
      </van-nav-bar>
    </navbarbox>
    <div class="hint">输入验证码查看证照信息</div>
    <div class="input_verify flex_sb">
      <div class="input_box">
        <van-field v-model="value" maxlength="6" />
      </div>
      <div class="canvas_box" @click="resetCode">
        <canavs :codes="codes" />
      </div>
    </div>
    <div class="btns">
      <van-button
        block
        class="btn"
        :class="{ active: value.length > 0 }"
        @click="submit"
      >
        确定
      </van-button>
      <!-- <van-button block class="btn" @click="test"> 测试 </van-button> -->
    </div>
  </div>
</template>

<script>
import handleLeft from "@/mixin/handleLeft.js";
import getTokenMixin from "@/mixin/getToken.js";
import navbarbox from "@/components/common/navbarbox.vue";
import canavs from "./canvas.vue";
export default {
  components: { canavs, navbarbox },
  mixins: [handleLeft, getTokenMixin],
  props: {},
  data() {
    return {
      value: "",
      codes: [],
      code_Len: 4,
      chars: "abcdefghijkmnpqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ23456789",
    };
  },
  computed: {},
  methods: {
    resetCode() {
      this.setcode();
    },
    setcode() {
      this.codes = [];
      const charsArr = this.chars.split("");
      for (let i = 0; i < this.code_Len; i++) {
        const num = Math.floor(Math.random() * charsArr.length);
        this.codes.push(charsArr[num]);
      }
    },
    submit() {
      if (this.value.trim() == "") {
        return;
      }
      const codes = this.codes.join("").toLowerCase();
      const value = this.value.trim().toLowerCase();
      if (codes === value) {
        this.$router.replace("/idPhoto");
      } else {
        this.$toast.fail("验证码错误");
        this.setcode();
      }
    },
    test() {
      this.getToken();
    },
  },
  created() {
    this.setcode();
    this.$store.commit("seller/setShopId", this.$route.params.shopId);
  },
  mounted() {
    this.getToken();
  },
  watch: {},
};
</script>

<style scoped lang="scss">
.verify_box {
  width: 100%;
  height: 100%;
  background: #f2f2f2;
  ::v-deep .van-nav-bar__left {
    .van-icon {
      color: #333;
    }
  }
  .hint {
    padding: 41px 0;
    text-align: center;
    font-size: 14px;
    color: #515151;
  }
  .input_verify {
    padding: 0 38px;
    margin-bottom: 26px;
    .input_box {
      width: 130px;
      background: #e8e7e7;
      ::v-deep .van-cell {
        height: 34px;
        line-height: 34px;
        padding: 0 5px;
        background: #e8e7e7;
      }
    }
    .canvas_box {
      width: 120px;
      background: #e8e7e7;
      height: 34px;
      line-height: 34px;
    }
  }
  .btns {
    padding: 0 38px;
    .btn {
      background: #cccccc;
      color: #515151;
      border-radius: 6px;
      border: 0px;
    }
    .active {
      background: #f00;
      color: #fff;
    }
  }
}
</style>
