/*
 * @Autor: oops.liu
 * js获取Apptoken事件
 */
import native from "@/utils/native.js";
import LocalCache from "@/utils/cache.js";
import { GET_TOKEN, JSGETTOKEN, TOKENKEY } from "@/mixin/mixin_file.js";
export default {
  methods: {
    /**
     * @description:
     * @param {*}item，js获取Apptoken事件
     * @return {*}
     */
    // 调用
    getToken(item) {
      LocalCache.deleteCache(TOKENKEY);
      native(GET_TOKEN, null);
    },
    setToken(token) {
      if (token) {
        LocalCache.setCache(TOKENKEY, token);
        if (this.otherMethod) {
          this.otherMethod();
        }
      }
    },
  },
  mounted() {
    window[JSGETTOKEN] = this.setToken;
    this.getToken();
  },
};
